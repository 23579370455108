import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';

const WhatsAppRegisterToVote = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Save our Future! Vote in the EU elections - here's how 🗳"
      description="Click the link and send a message to friends on WhatsApp - give them a voice! ➡️"
      shareImage="registertovote"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Hey! We can make a difference in the EU elections this May.

Send this message to your friends and groups!
Click here: https://saveourfuture.uk/registertovote

*Register to vote by May 7th*
Follow the simple steps here:
saveourfuture.uk/getvoting

Do it now! (Top tip: go for a postal vote)

*IMPORTANT !!!*
💥 Send this message to your friends and groups! 💥
Click here: https://saveourfuture.uk/registertovote

🗣 *THIS TIME, LET'S MAKE OUR VOICE HEARD!!!* 🗣`}
    />
    <Interstitial />
  </Layout>
);

WhatsAppRegisterToVote.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppRegisterToVote;

export const query = graphql`
  query WhatsAppRegisterToVoteQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
